import React, { useState, useEffect } from 'react'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import { Form, Input, Select, DatePicker, Badge, Menu, Dropdown, Radio } from 'antd'
import { toast } from 'react-toastify'

import PageContent from '../../components/PageContent'
import PageHeader from '../../components/PageHeader'

import PubSub from 'pubsub-js'

import Tag from '../../components/Tag'
import Icon from '../../components/Icon'
import Table from '../../components/Table'
import Backend from '../../api/Backend'
import Utils from '../../components/Utils'
import Drawer from '../../components/Drawer'
import DecisionModal from '../../components/DecisionModal'
import AllowRender from '../../components/AllowRender'
import Button from '../../components/Button'
import Patient from '../../pages/Patient'
import OnboardPatientView from '../../components/OnboardPatientView'

import { LangContext } from '../../components/Translation'
import translations from './translations'
import { useGlobalContext } from '../../components/AppContext'

import './index.css'

const { Option } = Select

const DEFAULT_TOAST_TIMER = 5000
const PATIENT_TABLE_FILTER_LOCALSTORAGE_KEY = 'PATIENT_TABLE_COLUMN_FILTER'

export default function Patients() {
	const { pushNotification, studyStates, isBetterCareProject, isEnavProject, isProductionSystem, isEnavStagingPatientRegistration } =
		useGlobalContext()

	const [form] = Form.useForm()
	const utils = new Utils()

	const lang = React.useContext(LangContext)(translations)
	const context = useGlobalContext()
	const selectedClinic = context.globals.selectedCenter

	const backend = new Backend()
	const DATE_FORMAT = 'DD.MM.YYYY'

	let history = useHistory()

	const [openPatientId, setOpenPatientId] = useState()

	const [loading, setLoading] = useState()

	const [patientsData, setPatientsData] = useState()
	const [studies, setStudies] = useState()
	const [selectedPatient, setSelectedPatient] = useState()
	const [newPatientData, setNewPatientData] = useState([])
	const [showPatientRegistrationForm, setShowPatientRegistrationForm] = useState(false)
	const [showPatientOnboardingForm, setShowPatientOnboardingForm] = useState(false)
	const [patientPasswordResetModal, setPatientPasswordResetModal] = useState()
	const [isHttpsRequestInProgress, setIsHttpsRequestInProgress] = useState(false)
	const [localStorageFilter, setLocalStorageFilter] = useState({})
	const [isLocalStorageReset, setIsLocalStorageReset] = useState(false)

	const location = useLocation()

	const fetchPatients = ({ silent = false }) => {
		if (!silent) {
			setLoading(true)
		}
		backend.patients({
			clinic: selectedClinic,
			cb: (patients) => {
				setPatientsData(
					patients.map((p) => {
						return {
							resource: p.resource,
							online: p.online,
							resourceStudy: p.resourceStudy,
							id: p.id,
							communication: p.communicationPermissions,
							patientName: p.patient_name.toUpperCase(),
							name: p.resource ? p.resource.name : '*',
							surname: p.resource ? p.resource.surname : '*',
							birthdate: p.resource ? p.resource.birthdate : '*',
							studyTitle: p.resourceStudy ? p.resourceStudy.title : '*',
							interventionGroup: p.intervention_group,
							setting: p.setting ? p.setting : '-',
							status: p.resourceStudy ? lang(p.resourceStudy.status.toLowerCase()) : '',
							createdAt: p.created_at,
							clinicId: p.clinic_id
						}
					})
				)
				setLoading(false)
			}
		})
	}

	useEffect(() => {
		let persistedFilterValue = JSON.parse(window.localStorage.getItem(PATIENT_TABLE_FILTER_LOCALSTORAGE_KEY))
		if (persistedFilterValue) {
			setLocalStorageFilter(persistedFilterValue)
		}
	}, [isLocalStorageReset])

	useEffect(() => {
		window.localStorage.setItem(PATIENT_TABLE_FILTER_LOCALSTORAGE_KEY, JSON.stringify(localStorageFilter))
	}, [localStorageFilter])

	useEffect(() => {
		setLoading(true)
		if (selectedClinic) {
			setLoading(false)
			fetchPatients({ silent: false })
		}
	}, [selectedClinic])

	useEffect(() => {
		if (pushNotification?.type === 'PATIENT') {
			fetchPatients({ silent: true })
		}
	}, [pushNotification])

	useEffect(() => {
		backend.studies({
			availableStudies: true,
			cb: (data) => {
				setStudies(
					data.map((dat) => {
						return {
							key: dat.study_id,
							value: dat.title
						}
					})
				)
			}
		})
	}, [])

	const handlePatientSelection = (patientId) => {
		setSelectedPatient(patientId)
		history.push('/patients')
	}

	const renderPatientRedirect = () => {
		return selectedPatient ? (
			<Redirect
				to={{
					pathname: `/patient/${selectedPatient}`,
					state: { referrer: location.pathname }
				}}
			/>
		) : null
	}

	const resetFields = () => {
		form.resetFields()
	}

	const createPatient = async () => {
		await form
			.validateFields()
			.then(() => {
				let fields = form.getFieldsValue()

				const data = {
					skipScreening: fields.screeningSkip,
					name: fields.name,
					pseudonym: fields.patientPseudonym,
					study: studies
						.filter((stud) => fields.study.includes(stud.value))
						.map((stud) => {
							return {
								studyId: stud.key,
								title: stud.value
							}
						}),
					surname: fields.surname,
					birthdate: fields.birthdate,
					email: fields.email,
					consent: fields.consent,
					zip: fields.zip,
					phone: fields.phone
				}

				if (selectedClinic) {
					data.clinicId = selectedClinic
				}

				const loadingToast = toast.loading(lang('request_processing'), { closeOnClick: true })

				const body = JSON.stringify(data)

				backend.patients({
					type: backend.type.CREATE,
					body,
					cb: () => {
						toast.update(loadingToast, {
							render: lang('finish_and_exit'),
							type: 'success',
							isLoading: false,
							closeOnClick: true,
							autoClose: DEFAULT_TOAST_TIMER
						})
						setIsHttpsRequestInProgress(false)
						setShowPatientRegistrationForm(false)
						fetchPatients({ silent: false })
					},
					cbError: (err) => {
						setIsHttpsRequestInProgress(false)
						if (err.status === 422) {
							toast.update(loadingToast, {
								render: lang('email_exists'),
								type: 'error',
								isLoading: false,
								closeOnClick: true,
								autoClose: DEFAULT_TOAST_TIMER
							})
						} else if (err.status === 409) {
							toast.update(loadingToast, {
								render: lang('pseudonym_exists'),
								type: 'error',
								isLoading: false,
								closeOnClick: true,
								autoClose: DEFAULT_TOAST_TIMER
							})
						} else {
							toast.update(loadingToast, {
								render: lang('processing_error'),
								type: 'error',
								isLoading: false,
								closeOnClick: true,
								autoClose: DEFAULT_TOAST_TIMER
							})
						}
					}
				})
				resetFields()
			})
			.catch(() => setIsHttpsRequestInProgress(false))
	}

	const resetPatientPw = (row) => {
		if (!row) return
		const loadingToast = toast.loading(lang('request_processing'))

		backend.patients({
			id: row.id,
			type: backend.type.UPDATE,
			resetPassword: true,
			cb: () => {
				toast.update(loadingToast, { render: lang('password_reset_success'), type: 'success', isLoading: false, autoClose: true })
			}
		})
	}

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	const formSheet = (
		<Form form={form} layout="vertical">
			<Form.Item name="screeningSkip" initialValue={true} label={lang('initial_status')}>
				<Radio.Group name="screeningSkip">
					<Radio value={true}>{lang('included-text')}</Radio>
					<Radio value={false}>{lang('screening')}</Radio>
				</Radio.Group>
			</Form.Item>
			{isBetterCareProject() ? (
				<Form.Item
					name="patientPseudonym"
					label={lang('patient-id')}
					rules={[
						{
							min: 11,
							message: lang('min-digits')
						},
						{
							max: 11,
							message: lang('max-digits')
						}
					]}>
					<Input showCount={true} disabled={isHttpsRequestInProgress} />
				</Form.Item>
			) : null}

			<Form.Item name="study" label={lang('study')} rules={rules} initialValue={studies && studies[0].value}>
				<Select disabled={isHttpsRequestInProgress}>
					{studies &&
						studies.map((study) => (
							<Option key={study.key} value={study.value}>
								{study.value}
							</Option>
						))}
				</Select>
			</Form.Item>
			<Form.Item name="consent" label={isBetterCareProject() ? lang('consent_given_bettercare') : lang('consent_given')} rules={rules}>
				<DatePicker disabled={isHttpsRequestInProgress} format={DATE_FORMAT} disabledPrevDays />
			</Form.Item>
			<Form.Item name="name" label={lang('first_name')} rules={rules}>
				<Input disabled={isHttpsRequestInProgress} />
			</Form.Item>
			<Form.Item name="surname" label={lang('surname')} rules={rules}>
				<Input disabled={isHttpsRequestInProgress} />
			</Form.Item>
			<Form.Item name="birthdate" label={lang('date_of_birth')} rules={rules}>
				<DatePicker disabled={isHttpsRequestInProgress} format={DATE_FORMAT} disabledPrevDays />
			</Form.Item>
			<Form.Item
				name="email"
				label="Email"
				rules={[
					{
						required: true,
						pattern: new RegExp(
							/^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
						),
						message: lang('email_format')
					}
				]}>
				<Input disabled={isHttpsRequestInProgress} />
			</Form.Item>
			<Form.Item
				name="zip"
				label={lang('zip-code')}
				rules={[
					{ required: true, message: lang('missing-zip-code') },
					{
						validator(_, value) {
							if (!value.match(/^\d{5}$/)) {
								return Promise.reject(lang('invalid-zip-code'))
							} else {
								return Promise.resolve()
							}
						}
					}
				]}>
				<Input disabled={isHttpsRequestInProgress} />
			</Form.Item>
			<Form.Item
				name="phone"
				label={lang('mob_phone')}
				rules={[
					{
						required: true,
						pattern: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$',
						message: lang('phone_format')
					}
				]}>
				<Input number disabled={isHttpsRequestInProgress} />
			</Form.Item>
		</Form>
	)

	const actions = {
		GOTO: 'GOTO',
		ONBOARD: 'ONBOARD',
		RESET_PASSWORD: 'RESET PASSWORD',
		CHAT: 'CHAT',
		VIDEO: 'VIDEO'
	}

	const renderActions = ({ row }) => {
		const handleMenuClick = (e) => {
			if (e.key === actions.GOTO) {
				handlePatientSelection(row.id)
			} else if (e.key === actions.ONBOARD) {
				row.status = studyStates.INCLUDED
				setNewPatientData(row)
				setShowPatientOnboardingForm(true)
			} else if (e.key === actions.CHAT) {
				PubSub.publish('chat:start', { patientId: row.id, patientName: row.name })
			} else if (e.key === actions.VIDEO) {
				PubSub.publish('video:start', { patientId: row.id, patientName: row.name })
			} else if (e.key === actions.RESET_PASSWORD) {
				setPatientPasswordResetModal(row)
			}
		}

		const menu = (
			<Menu onClick={handleMenuClick}>
				<Menu.Item
					disabled={row.status !== lang(studyStates.PARTICIPATION.toLowerCase()) || !row?.communication?.CHAT || !row.interventionGroup}
					key={actions.CHAT}>
					<a target="_blank" rel="noopener noreferrer">
						{lang('chat')}
					</a>
				</Menu.Item>
				<Menu.Item
					disabled={row.status !== lang(studyStates.PARTICIPATION.toLowerCase()) || !row?.communication?.VIDEO || !row.interventionGroup}
					key={actions.VIDEO}>
					<a target="_blank" rel="noopener noreferrer">
						{lang('video')}
					</a>
				</Menu.Item>
				<Menu.Item key={actions.GOTO}>
					<a target="_blank" rel="noopener noreferrer">
						{lang('goto-patient')}
					</a>
				</Menu.Item>
				<Menu.Item
					disabled={
						row.status !== lang(studyStates.ONBOARDING.toLowerCase()) && row.status !== lang(studyStates.PARTICIPATION.toLowerCase())
					}
					key={actions.RESET_PASSWORD}>
					<AllowRender permissions={['PATIENT_RW']}>{lang('reset_password')}</AllowRender>
				</Menu.Item>
				<Menu.Item disabled={row.status !== lang(studyStates.INCLUDED.toLowerCase())} key={actions.ONBOARD}>
					<a target="_blank" rel="noopener noreferrer">
						<AllowRender permissions={['PATIENT_RW']}>{lang('onboard')}</AllowRender>
					</a>
				</Menu.Item>
			</Menu>
		)

		return (
			<Dropdown.Button onClick={() => setOpenPatientId(row.id)} overlay={menu}>
				<Icon type="open"></Icon>
			</Dropdown.Button>
		)
	}

	return (
		<>
			<AllowRender permissions={['PATIENT_R']}>
				<PageHeader title={lang('patients')}>
					<PageContent>
						{renderPatientRedirect()}
						<div className="container-xl exo-patients">
							<div className="row row-deck row-cards">
								<div className="col-sm-12 col-lg-12">
									<Drawer
										key={Utils.randomID()}
										placement="right"
										frameless
										className="exo-patients-details"
										width="90%"
										onClose={() => {
											setOpenPatientId(undefined)
											fetchPatients({ silent: false })
										}}
										open={openPatientId}
										text={{
											title: lang('details'),
											cancel: lang('cancel')
										}}>
										<Patient
											clinic={selectedClinic}
											fetchPatients={() => {
												fetchPatients({ silent: false })
											}}
											setOpenPatientId={setOpenPatientId}
											id={openPatientId}></Patient>
									</Drawer>
									<DecisionModal
										bordered
										open={patientPasswordResetModal}
										onOK={() => {
											resetPatientPw(patientPasswordResetModal)
											setPatientPasswordResetModal(undefined)
										}}
										onCancel={() => setPatientPasswordResetModal(undefined)}
										text={{
											title: lang('reset_password'),
											ok: lang('reset'),
											cancel: lang('cancel'),
											details: `${lang('reset_password_for', [patientPasswordResetModal?.resource?.email])}`
										}}
									/>
									<Table
										key={Utils.randomID()}
										pagination={{
											defaultPageSize: 10,
											pageSizeOptions: [5, 10, 20, 50, 100],
											showSizeChanger: true
										}}
										loading={loading}
										emptyText={lang('no-data')}
										setLocalStorageFilter={setLocalStorageFilter}
										localStorageDataKey={PATIENT_TABLE_FILTER_LOCALSTORAGE_KEY}
										title={lang('title')}
										columns={[
											{
												width: 20,
												key: 'online',
												value: lang('online'),
												render: ({ value }) => {
													const color = value ? 'cyan' : 'default'
													return <Badge status={color} />
												}
											},
											{
												width: 40,
												key: 'patientName',
												value: lang('patient-id'),
												render: ({ value }) => {
													return value
												},
												defaultFilteredValue: localStorageFilter?.patientName
													? [localStorageFilter?.patientName || '']
													: null,
												sorter: (a, b) => a?.patientName?.localeCompare(b?.patientName),
												search: true
											},
											{
												key: 'name',
												width: 40,
												value: lang('name'),
												defaultFilteredValue: localStorageFilter?.name ? [localStorageFilter?.name || ''] : null,
												sorter: (a, b) => a?.name?.localeCompare(b?.name),
												search: true
											},
											{
												key: 'surname',
												width: 50,
												value: lang('surname'),
												defaultFilteredValue: localStorageFilter?.surname ? [localStorageFilter?.surname || ''] : null,
												sorter: (a, b) => {
													if (!a.surname) console.log(a)
													return a?.surname?.localeCompare(b?.surname)
												},

												search: true
											},
											{
												key: 'birthdate',
												width: 50,
												value: lang('date-of-birth'),
												render: ({ value }) => {
													return value !== '*' ? utils.toDate({ dateString: value, withoutTime: true }) : '*'
												}
											},
											{
												key: 'interventionGroup',
												width: 40,
												value: lang('group'),
												render: ({ row, value }) => {
													if (value) {
														return lang('intervention')
													} else if (
														isBetterCareProject() &&
														!value &&
														(row.status === lang(studyStates.ONBOARDING.toLowerCase()) ||
															row.status === lang(studyStates.PARTICIPATION.toLowerCase()) ||
															row.status === lang(studyStates.DROP_OUT.toLowerCase()))
													) {
														return lang('intervention')
													} else if (
														row.status === lang(studyStates.ONBOARDING.toLowerCase()) ||
														row.status === lang(studyStates.PARTICIPATION.toLowerCase()) ||
														(row.status === lang(studyStates.DROP_OUT.toLowerCase()) && !value)
													) {
														return lang('control')
													} else return '-'
												}
											},
											{
												key: 'status',
												width: 40,
												value: lang('status'),
												defaultFilteredValue: localStorageFilter?.status ? [localStorageFilter?.status || ''] : null,
												render: ({ value }) => {
													return <Tag type={value}>{value}</Tag>
												},
												sorter: (a, b) => a?.status?.localeCompare(b?.status),
												searchWithRender: true
											},
											{
												key: 'setting',
												width: 40,
												value: lang('setting'),
												defaultFilteredValue: localStorageFilter?.setting ? [localStorageFilter?.setting || ''] : null,
												sorter: (a, b) => a?.setting?.localeCompare(b?.setting),
												search: true
											},
											{
												key: 'createdAt',
												width: 50,
												value: lang('created-at'),
												render: ({ value }) => {
													return utils.toDate({ dateString: value, withoutTime: true })
												},
												dateSorter: 'createdAt'
											},
											{ width: 40, fixed: 'right', key: 'actions', value: lang('actions'), render: renderActions }
										]}
										data={patientsData}
										buttons={
											<>
												<AllowRender permissions={['PATIENT_RW']}>
													<Button
														disabled={Object.keys(localStorageFilter).length < 1}
														style={{ marginRight: '10px' }}
														type="filter-reset"
														onClick={() => {
															localStorage.clear()
															setIsLocalStorageReset(true)
															setLocalStorageFilter({})
														}}>
														{lang('reset-filters')}
													</Button>
													<Button
														type="add"
														disabled={isProductionSystem() && isEnavProject() && !isEnavStagingPatientRegistration()}
														onClick={() => setShowPatientRegistrationForm(true)}>
														{lang('register_patient')}
													</Button>
												</AllowRender>
											</>
										}
									/>
									<Drawer
										width="800px"
										open={showPatientRegistrationForm}
										disabled={isHttpsRequestInProgress}
										onClose={() => {
											resetFields()
											setShowPatientRegistrationForm(false)
											setIsHttpsRequestInProgress(false)
										}}
										onCancel={() => {
											setShowPatientRegistrationForm(false)
											setIsHttpsRequestInProgress(false)
											resetFields()
										}}
										text={{
											title: lang('register_patient'),
											ok: lang('register'),
											cancel: lang('cancel')
										}}
										onOK={() => {
											setIsHttpsRequestInProgress(true)
											createPatient()
										}}>
										{formSheet}
									</Drawer>
									<OnboardPatientView
										DEFAULT_TOAST_TIMER={DEFAULT_TOAST_TIMER}
										showPatientOnboardingForm={showPatientOnboardingForm}
										setShowPatientOnboardingForm={setShowPatientOnboardingForm}
										newPatientData={newPatientData}
										fetchPatients={() => {
											fetchPatients({ silent: false })
										}}
										rules={rules}
									/>
								</div>
							</div>
						</div>
					</PageContent>
				</PageHeader>
			</AllowRender>
		</>
	)
}
