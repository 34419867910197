import React, { useState } from 'react'
import { Table as AntdTable, Card, Input, Button, Space } from 'antd'
import Highlighter from 'react-highlight-words'

import { UpOutlined, DownOutlined, SearchOutlined } from '@ant-design/icons'

import Skeleton from '../Skeleton'
import Empty from '../Empty'
import Utils from '../Utils'

import { LangContext } from '../Translation'

import moment from 'moment'

import './index.css'

export default function Table({
	key = null,
	columns,
	components,
	showHeader, //hidden feature to unlock registration for enav staging/production
	rowMover = false,
	onRowMove,
	rowClassName,
	footer,
	setLocalStorageFilter = () => {},
	localStorageDataKey = null,
	data = [],
	emptyText,
	buttons,
	expandable,
	rowSelection,
	onRow,
	borderless = true,
	tableTitle,
	title,
	pagination = true,
	style,
	loading = false,
	className = '',
	scroll = null
}) {
	const utils = new Utils()
	const lang = React.useContext(LangContext)()

	const [searchText, setSearchText] = useState('')
	const [searchedColumn, setSearchedColumn] = useState('')

	let placeholder = null

	const defaultRenderer = (col) => {
		if (col.isDate) {
			return (text) => utils.toDate({ dateString: text })
		}
		return null // no rendering
	}

	const getLocalStorageData = () => {
		if (localStorageDataKey) {
			return [JSON.parse(window.localStorage.getItem(localStorageDataKey))]
		}
		return null
	}

	const removoLocalStorageDataObjectKey = (dataIndex) => {
		const items = getLocalStorageData()

		const objEntries = Object.fromEntries(Object.entries(items[0]).filter((item) => item[0] !== dataIndex))

		setLocalStorageFilter(objEntries)
	}

	const checkColumnDefinitions = () => {
		for (let [i, col] of columns.entries()) {
			if (!col.key || col.key === '') {
				console.warn('Table Control', `column at index ${i} must have a proper key definition`)
			}
			if (!col.value) {
				console.warn('Table Control', `column ${col.key} at index ${i} has missing value`)
			}
		}
	}
	checkColumnDefinitions()

	if (loading) {
		placeholder = <Skeleton className="p10" type="list" />
	} else if (!loading && data && data.length === 0) {
		placeholder = <Empty description={emptyText} />
	}

	className = `${className} exo-table`
	className = `${className} ${borderless ? 'borderless' : null}`

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm()
		setSearchText(selectedKeys[0])
		setLocalStorageFilter((prevFilter) => ({ ...prevFilter, [dataIndex]: selectedKeys[0] }))
		setSearchedColumn(dataIndex)
	}

	const handleReset = (clearFilters, confirm, dataIndex) => {
		clearFilters()
		setSearchText('')
		removoLocalStorageDataObjectKey(dataIndex)
		confirm({
			closeDropdown: true
		})
	}

	const getColumnSearchProps = (dataIndex, customRenderer = false) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					placeholder={''}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}>
						{lang('search')}
					</Button>
					<Button
						onClick={() => {
							handleReset(clearFilters, confirm, dataIndex, selectedKeys)
						}}
						size="small"
						style={{ width: 120 }}>
						{lang('reset')}
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value?.toLowerCase()) : ''),
		render: (text, record, index) => {
			if (searchedColumn === dataIndex && !customRenderer) {
				return (
					<Highlighter
						highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
						searchWords={[searchText]}
						autoEscape
						textToHighlight={text ? text.toString() : ''}
					/>
				)
			} else if (searchedColumn === dataIndex && customRenderer) {
				return customRenderer({ value: text, row: record, index })
			} else if (searchedColumn !== dataIndex && customRenderer) {
				return customRenderer({ value: text, row: record, index })
			} else {
				return text
			}
		}
	})

	const moveRow = (index, direction) => {
		const arrayMove = (arr, fromIndex, toIndex) => {
			var element = arr[fromIndex]
			arr.splice(fromIndex, 1)
			arr.splice(toIndex, 0, element)
			return arr
		}

		onRowMove(arrayMove(data, index, index + direction))
	}

	const renderRowMover = ({ index }) => {
		return (
			<>
				<Button shape="circle" onClick={() => moveRow(index, 1)} disabled={index === data.length - 1} icon={<DownOutlined />} />
				<Button className="ml5 mr15" shape="circle" onClick={() => moveRow(index, -1)} disabled={index === 0} icon={<UpOutlined />} />
			</>
		)
	}

	if (rowMover && onRowMove && columns.filter((col) => col.key === 'rowMover').length === 0) {
		columns.push({ width: 100, key: 'rowMover', value: lang('order'), render: renderRowMover })
	}
	const tableColumns = columns.map((col) => {
		let def = {
			title: col.value,
			dataIndex: col.key,
			key: col.key,
			fixed: col.fixed,
			width: col.width,
			sorter: col.sorter,
			defaultSortOrder: col.defaultSortOrder,
			defaultFilteredValue: col.defaultFilteredValue,
			align: col.align,
			filter: col.filter
		}
		if (col.dateSorter) {
			def.sorter = (a, b) => moment(a[col.dateSorter]).unix() - moment(b[col.dateSorter]).unix()
		}
		if (col.dateSorter) {
			def.sorter = (a, b) => moment(a[col.dateSorter]).unix() - moment(b[col.dateSorter]).unix()
		}
		if (col.search) {
			def = { ...getColumnSearchProps(def.dataIndex), ...def }
		} else if (col.searchWithRender) {
			def = { ...getColumnSearchProps(def.dataIndex, col.render), ...def }
		} else {
			def.render = col.render
				? (text, record, index) => {
						return col.render({ value: text, row: record, index })
				  }
				: defaultRenderer(col)
		}
		if (!col.editable && !col.selector) {
			return def
		} else {
			return {
				...def,
				onCell: (record) => ({
					record,
					editable: col.editable,
					selector: col.selector,
					dataIndex: def.dataIndex,
					title: def.title,
					onCellSave: col.onCellSave
				})
			}
		}
	})

	return (
		<Card title={title} style={style} extra={buttons} className={className}>
			{placeholder ? (
				placeholder
			) : (
				<AntdTable
					key={key}
					showHeader={showHeader}
					rowSelection={rowSelection}
					expandable={expandable}
					columns={tableColumns}
					components={components}
					rowClassName={rowClassName}
					onRow={onRow}
					dataSource={data}
					sticky
					pagination={pagination}
					scroll={scroll}
					footer={footer}
					title={tableTitle}
				/>
			)}
		</Card>
	)
}
